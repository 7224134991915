<template>
  <div>
    <v-stepper class="mt-n5" v-model="e1">
      <v-progress-linear
        color="primary"
        buffer-value="0"
        :value="(e1 / 7) * 100"
        class="mt-"
      ></v-progress-linear>
      <v-stepper-header>
        <v-stepper-step step="1" :complete="e1 > 1">
          Need
          <small>Need Identification</small>
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="2" :complete="e1 > 2">
          Pre-approval
          <small>Obtain Pre-approval</small>
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="3" :complete="e1 > 3">
          Supplier Selection
          <small>Select pre-qualified suppliers</small>
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="4" :complete="e1 > 4">
          Approval
          <small>as per approval authority limits</small>
        </v-stepper-step>

        <v-divider></v-divider>
        <v-stepper-step step="5" :complete="e1 > 5">
          Contract
          <small>Initiation</small>
        </v-stepper-step>

        <v-stepper-step step="6" :complete="e1 > 6">
          Purchase
          <small>Requisition</small>
        </v-stepper-step>

        <v-stepper-step step="7" :complete="e1 > 7">
          Purchase
          <small>Order</small>
        </v-stepper-step>
        <v-stepper-step step="8" :complete="e1 > 8">
          Delivery
          <small>GRN,QI,JAC</small>
        </v-stepper-step>
      </v-stepper-header>
    </v-stepper>
    <v-divider class="my-2"></v-divider>
    <v-data-table
      :headers="headers"
      :items="items"
      sort-by="title"
      class="elevation-1"
      loading-text="Loading... Please wait"
      :search="search"
      :custom-filter="filterText"
    >
      <template v-slot:top>
        <v-toolbar flat class="pt-2">
          <v-row>
            <v-col cols="8">
              <span class="text-h6 error--text text--darken-1">{{ name }}</span>
            </v-col>
            <v-col cols="4">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="text-capitalize ml-1 float-right"
                    :loading="loading"
                    @click="goToSupplierSelection"
                    elevation="0"
                    fab
                    x-small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-skip-next-outline</v-icon>
                  </v-btn>
                </template>
                <span>Supplier selection</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="text-capitalize ml-1 float-right"
                    :loading="loading"
                    @click="goToNeedIdentification"
                    elevation="0"
                    fab
                    x-small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-skip-previous-outline</v-icon>
                  </v-btn>
                </template>
                <span>Back to need identification</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="text-capitalize ml-1 float-right"
                    :loading="loading"
                    @click="gotToRequisition"
                    elevation="0"
                    fab
                    x-small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-format-list-bulleted-square</v-icon>
                  </v-btn>
                </template>
                <span>Back to requisitions</span>
              </v-tooltip>
              <v-text-field
                label="search..."
                v-model="search"
                append-icon="mdi-magnify"
                dense
                width="54"
                color="primary"
                class="float-right"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-progress-linear
          indeterminate
          color="primary"
          v-if="loading"
        ></v-progress-linear>
        <v-divider></v-divider>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <a class="success--text">
          Waiting for
          <a v-if="item.workflow">{{
            item.workflow.approval_groups[item.status].title
          }}</a>
          Approval
        </a>
      </template>

      <template v-slot:[`item.attachment`]="{ item }">
        <a
          :href="item.attachment"
          target="_blank"
          v-if="item.attachment"
          class="text-decoration-none"
        >
          <v-icon color="primary">mdi-attachment</v-icon>
        </a>
        <a
          :href="item.attachment"
          target="_blank"
          v-else
          class="text-decoration-none"
        >
          N/A
        </a>
      </template>
      <template v-slot:[`item.workflow`]="{ item }">
        <a
          v-for="(wkfl, i) in item.workflow.approval_groups"
          :key="i"
          class="primary--text"
        >
          <span v-if="i != 0"> <v-icon>mdi-arrow-right-bold</v-icon> </span>
          <a v-if="i == item.status" class="success--text">{{ wkfl.title }}</a>
          <a v-else class="primary--text">{{ wkfl.title }}</a>
        </a>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <div class="my-1">
          <v-btn
            color="primary"
            class="mx-1"
            fab
            x-small
            dark
            @click="viewItemModal(item)"
          >
            <v-icon>mdi-eye</v-icon>
          </v-btn>

          <v-btn
            color="primary"
            class="mx-1"
            fab
            x-small
            dark
            @click="approveItem(item)"
            v-if="approvalStatus(item)"
          >
            <v-icon>mdi-check-all</v-icon>
          </v-btn>

          <v-btn
            color="warning"
            class="mx-1"
            fab
            x-small
            dark
            @click="rejectItem(item)"
            v-if="approvalStatus(item)"
          >
            <v-icon>mdi-cancel</v-icon>
          </v-btn>
        </div>
      </template></v-data-table
    >
    <div class="text-center">
      <v-dialog v-model="dialog_view" width="800">
        <v-card>
          <v-card-title class="headline grey text--white lighten-2">
            Requisition Approval
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12" class="my-n2">
                  <v-stepper v-model="e1">
                    <v-stepper-header>
                      <a
                        v-for="(wkfl, i) in editedItem.workflow.approval_groups"
                        :key="i"
                      >
                        <v-stepper-step
                          :complete="e1 > i"
                          step="1"
                          color="red darken-1"
                        >
                          {{ wkfl.title }}
                        </v-stepper-step>
                        <v-divider></v-divider>
                        <v-divider></v-divider>
                      </a>
                    </v-stepper-header>
                    <v-divider></v-divider>
                    <v-stepper-items>
                      <v-stepper-content step="1">
                        <v-btn
                          color="primary"
                          @click="e1 = 2"
                          class="ml-5 text-capitalize white--text"
                        >
                          Continue <v-icon>mdi-chevron-right</v-icon>
                        </v-btn>
                      </v-stepper-content>

                      <v-stepper-content step="2">
                        <div class="ml-3">
                          <v-btn
                            color="error"
                            @click="e1 = 1"
                            class="mb-1 mx-2 text-capitalize white--text"
                          >
                            <v-icon>mdi-chevron-left</v-icon> Back
                          </v-btn>
                          <v-btn
                            color="primary"
                            @click="e1 = 3"
                            class="mb-1 mx-5 text-capitalize white--text"
                          >
                            Continue <v-icon>mdi-chevron-right</v-icon>
                          </v-btn>
                        </div>
                      </v-stepper-content>

                      <v-stepper-content step="3">
                        <div class="ml-3">
                          <v-btn
                            color="primary darken-1 white--text"
                            @click="e1 = 2"
                            class="mb-1 mx-2 text-capitalize white--text"
                          >
                            <v-icon>mdi-chevron-left</v-icon> Back
                          </v-btn>
                          <v-btn
                            color="primary"
                            @click="e1 = 4"
                            class="mb-1 mx-2 text-capitalize white--text"
                          >
                            Continue <v-icon>mdi-chevron-right</v-icon>
                          </v-btn>
                        </div>
                      </v-stepper-content>

                      <v-stepper-content step="4">
                        <v-form ref="form" v-model="valid">
                          <v-row>
                            <v-col cols="12" md="12" class="ml-3"> </v-col>
                          </v-row>
                        </v-form>
                      </v-stepper-content>
                    </v-stepper-items>
                  </v-stepper>
                </v-col>

                <v-divider></v-divider>
                <v-col cols="12" sm="12" md="12" class="my-n2">
                  <div
                    class="pa-5 bt-3 outlined"
                    v-html="editedItem.description"
                  ></div>
                </v-col>
                <v-divider></v-divider>

                <v-col cols="12" sm="12" md="12" class="my-2">
                  <label>Requisition Items</label>

                  <template>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">#</th>
                            <th class="text-left">Name</th>
                            <th class="text-left">Quantity</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(requisition_item, i) in requisition_items"
                            :key="i"
                          >
                            <td data-label="No.">{{ i + 1 }}</td>
                            <td>
                              <v-text-field
                                v-model="requisition_item.name"
                                label="Name"
                                :rules="rules.items"
                                color="grey"
                                readonly
                                dense
                                outlined
                                class="mb-n3"
                              >
                                <template v-slot:label>
                                  <span class="input__label"
                                    >Name
                                    <v-icon small color="error" class="mt-n2"
                                      >mdi-star-outline</v-icon
                                    ></span
                                  >
                                </template>
                              </v-text-field>
                            </td>

                            <td>
                              <v-text-field
                                v-model="requisition_item.quantity"
                                label="Quantity"
                                :rules="rules.items"
                                color="grey"
                                readonly
                                dense
                                outlined
                                type="number"
                                class="mb-n3"
                              >
                                <template v-slot:label>
                                  <span class="input__label"
                                    >Quantity
                                    <v-icon small color="error" class="mt-n2"
                                      >mdi-star-outline</v-icon
                                    ></span
                                  >
                                </template>
                              </v-text-field>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </template>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialog_view = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <v-dialog v-model="dialog_approve" max-width="950px">
      <v-card>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card-title>
            <span class="headline error--text text--darken-1">{{
              formTitle
            }}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12" class="my-n2">
                  <v-text-field
                    v-model="editedItem.title"
                    label="Title"
                    :rules="rules.required"
                    color="grey"
                    dense
                    outlined
                    class="mb-n3"
                  >
                    <template v-slot:label>
                      <span class="input__label"
                        >Name
                        <v-icon small color="error" class="mt-n2"
                          >mdi-star-outline</v-icon
                        ></span
                      >
                    </template>
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="12" md="12" class="my-2">
                  <label>Description</label>
                  <ckeditor
                    class="w-full"
                    tag-name="textarea"
                    ref="cktext"
                    :editor="editor"
                    v-model="editedItem.description"
                    shouldnotGroupWhenFull="true,"
                    :config="editorConfig"
                  ></ckeditor>
                </v-col>

                <v-col cols="12" sm="12" md="12" class="my-2">
                  <label>Requisition Items</label>

                  <template>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">#</th>
                            <th class="text-left">Name</th>
                            <th class="text-left">Quantity</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(requisition_item, i) in requisition_items"
                            :key="i"
                          >
                            <td data-label="No.">{{ i + 1 }}</td>
                            <td>
                              <v-text-field
                                v-model="requisition_item.name"
                                label="Name"
                                :rules="rules.items"
                                color="grey"
                                readonly
                                dense
                                outlined
                                class="mb-n3"
                              >
                                <template v-slot:label>
                                  <span class="input__label"
                                    >Name
                                    <v-icon small color="error" class="mt-n2"
                                      >mdi-star-outline</v-icon
                                    ></span
                                  >
                                </template>
                              </v-text-field>
                            </td>

                            <td>
                              <v-text-field
                                v-model="requisition_item.quantity"
                                label="Quantity"
                                :rules="rules.items"
                                color="grey"
                                readonly
                                dense
                                outlined
                                type="number"
                                class="mb-n3"
                              >
                                <template v-slot:label>
                                  <span class="input__label"
                                    >Quantity
                                    <v-icon small color="error" class="mt-n2"
                                      >mdi-star-outline</v-icon
                                    ></span
                                  >
                                </template>
                              </v-text-field>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </template>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              class="text-capitalize mx-1"
              dark
              @click="dialog_approve = false"
            >
              Close <v-icon small>mdi-close</v-icon>
            </v-btn>
            <v-btn
              color="primary"
              dark
              class="text-capitalize mx-1"
              :loading="loading"
              @click="approveRequisition"
            >
              Approve <v-icon>mdi-content-save-outline</v-icon>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_reject" max-width="950px">
      <v-card>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card-title>
            <span class="headline error--text text--darken-1">{{
              formTitle
            }}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12" class="my-n2">
                  <v-text-field
                    v-model="editedItem.title"
                    label="Title"
                    :rules="rules.required"
                    color="grey"
                    dense
                    outlined
                    class="mb-n3"
                  >
                    <template v-slot:label>
                      <span class="input__label"
                        >Name
                        <v-icon small color="error" class="mt-n2"
                          >mdi-star-outline</v-icon
                        ></span
                      >
                    </template>
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="12" md="12" class="my-2">
                  <label>Description</label>
                  <ckeditor
                    class="w-full"
                    tag-name="textarea"
                    ref="cktext"
                    :editor="editor"
                    v-model="editedItem.description"
                    shouldnotGroupWhenFull="true,"
                    :config="editorConfig"
                  ></ckeditor>
                </v-col>

                <v-col cols="12" sm="12" md="12" class="my-2">
                  <label>Requisition Items</label>

                  <template>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">#</th>
                            <th class="text-left">Name</th>
                            <th class="text-left">Quantity</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(requisition_item, i) in requisition_items"
                            :key="i"
                          >
                            <td data-label="No.">{{ i + 1 }}</td>
                            <td>
                              <v-text-field
                                v-model="requisition_item.name"
                                label="Name"
                                :rules="rules.items"
                                color="grey"
                                readonly
                                dense
                                outlined
                                class="mb-n3"
                              >
                                <template v-slot:label>
                                  <span class="input__label"
                                    >Name
                                    <v-icon small color="error" class="mt-n2"
                                      >mdi-star-outline</v-icon
                                    ></span
                                  >
                                </template>
                              </v-text-field>
                            </td>

                            <td>
                              <v-text-field
                                v-model="requisition_item.quantity"
                                label="Quantity"
                                :rules="rules.items"
                                color="grey"
                                readonly
                                dense
                                outlined
                                type="number"
                                class="mb-n3"
                              >
                                <template v-slot:label>
                                  <span class="input__label"
                                    >Quantity
                                    <v-icon small color="error" class="mt-n2"
                                      >mdi-star-outline</v-icon
                                    ></span
                                  >
                                </template>
                              </v-text-field>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </template>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              class="text-capitalize mx-1"
              dark
              @click="dialog_reject = false"
            >
              Close <v-icon small>mdi-close</v-icon>
            </v-btn>
            <v-btn
              color="warning darken-1"
              class="text-capitalize mx-1"
              dark
              @click="rejectRequisition"
            >
              Reject <v-icon small>mdi-cancel</v-icon>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<style>
.ck-editor__editable {
  min-height: 250px;
}
.in-editor .ck-toolbar {
  display: inline !important;
}
</style>
<script>
import RequisitionService from "../../services/requisition.service";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import the component and the necessary extensions

export default {
  components: {},
  data: () => ({
    e1: 2,
    name: "Pending Approval",
    dialog_view: false,
    editor: ClassicEditor,
    editorConfig: {
      viewportTopOffset: 50,
      shouldNotGroupWhenFull: true,
      editorplaceholder: "Details here",
      toolbarCanCollapse: false,
      toolbar: {
        viewportTopOffset: 30,
        shouldNotGroupWhenFull: true,
      },
    },

    loading: true,
    items: [],
    search: "",
    requisition_items: [],
    editedIndex: -1,
    editedItem: {
      workflow: {
        approval_groups: {},
      },
    },
    defaultItem: {},
    remember: 0,
    timeout: 2000,
    dialog_approve: false,
    dialog_reject: false,
    valid: true,

    rules: {
      select: [(v) => !!v || "An item should be selected"],
      select2: [(v) => v.length > 0 || "Atleast one item should be selected"],
      required: [
        (v) => !!v || "Field is required",
        (v) => (v && v.length >= 3) || "Min 3 characters",
      ],
      file: [
        (v) => !!v || "File is required",
        (v) => (v && v.length > 0) || "File is required",
      ],
    },
    headers: [
      {
        text: "#",
        align: "start",
        filterable: true,
        value: "id",
      },
      {
        text: "Title",
        value: "title",
      },
      {
        text: "Workflow",
        value: "workflow",
      },
      {
        text: "Status",
        value: "status",
      },
      {
        text: "Attached Document",
        value: "attachment",
      },
      {
        text: "Actions",
        align: "right",
        value: "actions",
        sortable: false,
      },
    ],
  }),
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
    formTitle() {
      return this.editedIndex === -1 ? "New" : "Approve";
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    getLoading() {
      return this.loading;
    },
    // required docs
    likesAllRequiredDocument() {
      return (
        this.editedItem.required_documents.length ===
        this.required_documents.length
      );
    },
    likesSomerequired_documents() {
      return (
        this.editedItem.required_documents.length > 0 &&
        !this.likesAllRequiredDocument
      );
    },
    iconRequired() {
      if (this.likesAllRequiredDocument) return "mdi-close-box";
      if (this.likesSomerequired_documents) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  created() {
    this.index();
  },
  methods: {
    goToNeedIdentification() {
      this.$router.push("need-identification");
    },
    goToSupplierSelection() {
      this.$router.push("supplier-selection");
    },
    gotToRequisition() {
      this.$router.push("requisition");
    },
    trimText(text, length) {
      text.substring(0, length);
    },
    approveItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.requisition_items = this.editedItem.items;
      this.dialog_approve = true;
    },
    rejectItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.requisition_items = this.editedItem.items;
      this.dialog_reject = true;
    },
    viewItemModal(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.requisition_items = this.editedItem.items;
      this.dialog_view = true;
    },

    // search
    filterText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleLowerCase().indexOf(search) !== -1
      );
    },
    handleLogout() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    approvalStatus(item) {
      if (item.current_group == item.current_user) {
        if (item.approved == 1) {
          return false;
        }

        return true;
      }
    },
    index() {
      return RequisitionService.approvable_index(this.user).then(
        (response) => {
          if (response.status == 200) {
            console.log(response.data.data);
            this.items = response.data.data;
            this.loading = false;
          } else {
            this.items = [];
            console.log(response.data.error);
          }
        },
        (error) => {
          this.items = [];
          console.log(error);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    viewItem(item) {
      this.$router.push("/suppliers-view/" + item.id);
    },
    deleteItem(item) {
      const index = this.items.indexOf(item);
      var deleteUser = confirm("Are you sure you want to delete this item?");
      if (deleteUser) {
        RequisitionService.delete(item).then(
          (response) => {
            if (response.status == 200) {
              this.$store.dispatch("alert/success", response.data.message);
              this.items.splice(index, 1);
              this.close();
            } else {
              this.$store.dispatch("alert/error", "Error deleting document");
            }
          },
          (error) => {
            if (error.response.status == 422) {
              this.$store.dispatch("alert/error", error.response.data.message);
            } else if (error.response.status == 500) {
              this.$store.dispatch("alert/error", error.response.data.message);
            } else if (error.response.status == 502) {
              this.$store.dispatch("alert/error", error.response.data.message);
            } else if (error.response.status == 401) {
              this.handleLogout();
            } else {
              this.$store.dispatch("alert/error", error.response.statusText);
            }
          }
        );
      }
    },

    close() {
      this.dialog_approve = false;
      this.dialog_reject = false;
    },

    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },

    approveRequisition() {
      this.loading = true;
      this.$refs.form.validate();
      if (this.valid) {
        Object.assign(this.items[this.editedIndex], this.editedItem);
        this.editedItem.status += 1;
        RequisitionService.approve(this.editedItem).then(
          (response) => {
            if (response.status == 200) {
              this.$store.dispatch("alert/success", response.data.message);
              this.loading = false;
              this.index();
              this.close();
            } else {
              this.$store.dispatch("alert/error", response.data.message);
              this.index();
              this.loading = false;
            }
          },
          (error) => {
            if (error.response.status == 422) {
              this.$store.dispatch("alert/error", error.response.data.message);
              this.loading = false;
              this.index();
            }
            if (error.response.status == 401) {
              this.handleLogout();
              this.loading = false;
              this.index();
            }
            console.log(error);
            this.$store.dispatch("alert/error", error.response.data.message);
          }
        );
      } else {
        this.loading = false;
        this.index();
      }
    },

    rejectRequisition() {
      this.loading = true;
      this.$refs.form.validate();
      if (this.valid) {
        Object.assign(this.items[this.editedIndex], this.editedItem);
        this.editedItem.status -= 1;
        console.log(this.editedItem);
        RequisitionService.reject(this.editedItem).then(
          (response) => {
            if (response.status == 200) {
              this.$store.dispatch("alert/success", response.data.message);
              this.loading = false;
              this.index();
              this.close();
            } else {
              this.$store.dispatch("alert/error", response.data.message);
              this.index();
              this.loading = false;
            }
          },
          (error) => {
            if (error.response.status == 422) {
              this.$store.dispatch("alert/error", error.response.data.message);
              this.loading = false;
              this.index();
            }
            if (error.response.status == 401) {
              this.handleLogout();
              this.loading = false;
              this.index();
            }
            console.log(error);
            this.$store.dispatch("alert/error", error.response.data.message);
          }
        );
      } else {
        this.loading = false;
        this.index();
      }
    },
  },
};
</script>
